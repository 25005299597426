@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full w-full m-0;
}

:root {
  --wise-blue: rgb(99, 163, 255);
  --wise-gray: rgb(145, 152, 163);
  --wise-green: rgb(65, 191, 171);
  --wise-lightblue: rgba(99, 163, 255, 0.6);
  --wise-lightgray: rgb(233, 234, 237);
  --wise-lightgreen: rgb(182, 222, 217);
  --wise-lightpink: rgb(255, 163, 177);
  --wise-lightyellow: rgb(255, 230, 194);
  --wise-midgray: rgb(127, 133, 143);
  --wise-orange: rgb(255, 125, 86);
  --wise-pink: rgb(255, 86, 112);
  --wise-yellow: rgb(255, 199, 117);

  --wise-earth: rgb(65, 191, 172);
  --wise-earth-100: rgb(239, 252, 246);

  --wise-galaxy-50: rgb(246, 248, 251);
  --wise-galaxy-200: rgb(217, 226, 236);
  --wise-galaxy-300: rgb(89, 117, 146);
  --wise-galaxy-500: rgb(110, 120, 130);
  --wise-galaxy-800: rgb(9, 16, 26);

  --wise-mars: rgb(255, 86, 112);
  --wise-mars-100: rgb(255, 229, 233);

  --wise-neptune: rgb(49, 109, 190);
  --wise-neptune-100: rgb(243, 248, 255);

  --wise-ocean: rgb(94, 162, 255);
  --wise-ocean-500: rgb(71, 138, 229);

  --wise-saturn: rgb(255, 199, 117);
  --wise-saturn-100: rgb(255, 251, 245);
  --wise-saturn-500: rgb(255, 177, 64);

  --wise-venus-400: rgb(255, 130, 74);
  --wise-venus-100: rgb(255, 244, 239);
}

body {
  user-select: none;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
